import React from 'react';
import './RecoCompleteness.css';

const DEFAULT_VALUE = {
	name: 'Example Zip Code',
	semantic_type: 'US postal code',
	total: 789,
	valid: 654,
	invalid: 77,
	empty: 58,
	distinct: 52,
};

export default function RecoCompleteness(props) {
	const data = props.data.recommendation.completeness_breakdown.remarked_columns;
	let first;
	if (data.length > 0) {
		first = data[0];
	} else {
		first = DEFAULT_VALUE;
	}
	if (first.empty === 0) {
		first = DEFAULT_VALUE;
	}

	return (
		<div className="RecoCompleteness__panel">
			<div className="Report__panel RecoCompleteness__prep">
				<i className="fa fa-caret-down" />
				<h5 className="RecoCompleteness__title">{first.name}</h5>
				<p className="RecoCompleteness__semantic">
					{first.semantic_type === '' ? first.data_type : first.semantic_type}
				</p>
				<div className="progress">
					<div
						className="progress-bar bg-empty"
						role="progressbar"
						style={{ width: `${100.0 * first.empty / first.total}%` }}
						aria-valuenow="15"
						aria-valuemin="0"
						aria-valuemax="100">
					</div>
					<div
						className="progress-bar bg-other"
						role="progressbar"
						style={{ width: `${100.0 * (first.total - first.empty) / first.total}%` }}
						aria-valuenow="30"
						aria-valuemin="0"
						aria-valuemax="100">
					</div>
				</div>
			</div>
			<div className="Report__panel RecoCompleteness__actions">
				<div className="RecoCompleteness__prepactions">
					<p>Fill empty cells with value …</p>
					<p>Fill empty cells from above</p>
					<p>Magic fill based on …</p>
				</div>
				<div className="RecoCompleteness__tdsactions">
					<p className="font-weight-bold">Or assign this task</p>
					<div className="RecoCompleteness__fakefield">
						<label>Data steward:</label>
						<div className="">Rebecca O’Neill <i className="fa fa-caret-down" /></div>
					</div>
					<span className="btn-fake">Submit</span>
				</div>
			</div>
		</div>
	)
}
