import React from 'react';
import './Logo.css';

export default function Logo({ size, ...props }) {
	return (
	<svg version="1.1" id="Layer_2_00000122691654168283297900000009301703023988743064_"
		xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 336.1 123"
		xmlSpace="preserve">
	<g>
		<g>
			<path class="st0" d="M115.6,94.3h4.6l7,21.5H123l-1.6-5.8h-7.1l-1.6,5.8h-4L115.6,94.3z M115.1,107h5.4l-0.7-2.6
				c-0.7-2.3-1.3-4.7-1.9-7.1h-0.1c-0.6,2.4-1.2,4.8-1.9,7.1L115.1,107z"/>
			<path class="st0" d="M143,116.1c-4.6-0.8-7.7-4.9-7.7-11.1c0-6.9,3.9-11.1,9.5-11.1c5.6,0,9.5,4.1,9.5,11.1c0,6-2.8,10-7.2,11
				c1,1.7,3,2.4,5,2.4c1,0,1.7-0.1,2.3-0.3l0.7,2.8c-0.7,0.4-2,0.6-3.5,0.6C147.4,121.5,144.4,119.2,143,116.1z M150.3,105
				c0-4.8-2.2-7.6-5.5-7.6c-3.4,0-5.5,2.9-5.5,7.6c0,5,2.1,8,5.5,8C148.1,113,150.3,110,150.3,105z"/>
			<path class="st0" d="M158.2,111.6v-19h3.9v19.2c0,1,0.4,1.3,0.8,1.3c0.2,0,0.3,0,0.6-0.1l0.5,2.9c-0.5,0.2-1.1,0.4-2,0.4
				C159.3,116.2,158.2,114.4,158.2,111.6z"/>
			<path class="st0" d="M166.8,94.7c0-1.3,1-2.2,2.3-2.2s2.3,0.9,2.3,2.2c0,1.3-1,2.2-2.3,2.2S166.8,96,166.8,94.7z M167.2,99.6h3.9
				v16.2h-3.9V99.6z"/>
			<path class="st0" d="M175.9,92.6h3.8v14.3h0.1l5.8-7.3h4.3l-5.5,6.6l6.1,9.5h-4.2l-4.1-6.9l-2.5,2.8v4.1h-3.8V92.6z"/>
			<path class="st0" d="M198.9,105.1c0-7,4.4-11.2,9.9-11.2c2.7,0,4.9,1.3,6.2,2.7l-2.1,2.4c-1.1-1.1-2.4-1.7-4.1-1.7
				c-3.4,0-5.9,2.9-5.9,7.7c0,4.8,2.3,7.8,5.9,7.8c1.9,0,3.4-0.8,4.5-2.1l2.1,2.3c-1.8,2.1-4,3.1-6.8,3.1
				C203.2,116.2,198.9,112.3,198.9,105.1z"/>
			<path class="st0" d="M217.6,107.8c0-5.4,3.7-8.5,7.7-8.5c4,0,7.7,3.1,7.7,8.5c0,5.4-3.7,8.5-7.7,8.5
				C221.3,116.2,217.6,113.1,217.6,107.8z M229.1,107.8c0-3.2-1.4-5.3-3.7-5.3c-2.3,0-3.7,2.1-3.7,5.3c0,3.2,1.4,5.3,3.7,5.3
				C227.7,113.1,229.1,111,229.1,107.8z"/>
			<path class="st0" d="M236.7,99.6h3.2l0.3,2.2h0.1c1.4-1.4,2.9-2.6,5-2.6c2.4,0,3.7,1.1,4.5,2.9c1.5-1.6,3.1-2.9,5.2-2.9
				c3.4,0,5,2.4,5,6.4v10.1H256v-9.6c0-2.6-0.8-3.6-2.4-3.6c-1,0-2.1,0.6-3.3,2v11.3h-3.9v-9.6c0-2.6-0.8-3.6-2.4-3.6
				c-1,0-2.1,0.6-3.3,2v11.3h-3.9V99.6z"/>
			<path class="st0" d="M264.5,99.6h3.2l0.3,1.7h0.1c1.4-1.2,3.1-2.1,4.9-2.1c4,0,6.3,3.3,6.3,8.3c0,5.5-3.3,8.7-6.9,8.7
				c-1.4,0-2.9-0.7-4.1-1.8l0.1,2.7v5h-3.9V99.6z M275.3,107.5c0-3.2-1.1-5.1-3.5-5.1c-1.2,0-2.2,0.6-3.5,1.8v7.5
				c1.1,1,2.2,1.3,3.2,1.3C273.7,113,275.3,111.2,275.3,107.5z"/>
			<path class="st0" d="M281.9,111.5c0-3.6,2.8-5.3,9.5-6.1c-0.1-1.6-0.7-3-2.9-3c-1.6,0-3.1,0.7-4.5,1.5l-1.4-2.6
				c1.8-1.1,4.1-2.1,6.6-2.1c4,0,6.1,2.5,6.1,7v9.6h-3.2l-0.3-1.8h-0.1c-1.4,1.3-3,2.2-4.9,2.2C283.8,116.2,281.9,114.3,281.9,111.5z
				M291.3,111.5v-3.4c-4.4,0.5-5.7,1.5-5.7,3.2c0,1.4,0.9,1.9,2.2,1.9C289.2,113.1,290.1,112.6,291.3,111.5z"/>
			<path class="st0" d="M299.7,99.6h3.2l0.3,2.2h0.1c1.4-1.4,3.1-2.6,5.3-2.6c3.5,0,5,2.4,5,6.4v10.1h-3.9v-9.6
				c0-2.6-0.8-3.6-2.5-3.6c-1.4,0-2.3,0.7-3.6,2v11.3h-3.9V99.6z"/>
			<path class="st0" d="M317.2,122.1l0.8-2.9c0.5,0.1,0.8,0.2,1.1,0.2c1.7,0,2.7-1.1,3.2-2.7l0.3-1l-6.3-16h3.9l2.7,7.9
				c0.5,1.4,1,3,1.4,4.6h0.1c0.4-1.5,0.8-3.1,1.2-4.6l2.4-7.9h3.8l-5.8,16.9c-1.3,3.7-3.1,5.9-6.6,5.9
				C318.4,122.4,317.8,122.3,317.2,122.1z"/>
		</g>
		<g>
			<path class="st1" d="M280,79.7c-3.5-2.5-6.1-5.8-7.7-10c-1.6-4.1-2.4-8.7-2.4-13.8c0-4.9,1-9.4,3.1-13.6c2-4.2,5.1-7.6,9.1-10.1
				c4.1-2.5,9-3.8,14.8-3.8c3,0,5.5,0.2,7.6,0.7c2.1,0.5,4.2,1.4,6.5,2.8l-0.4-20.1c0-2.3-0.9-3.9-2.7-4.9c-1.8-0.9-4.4-1.5-7.9-1.8
				c-0.4-0.2-0.7-0.4-0.9-0.7s-0.3-0.8-0.3-1.4c0-0.5,0.1-1,0.4-1.5c0.2-0.5,0.5-0.8,0.9-0.8c5.5-0.3,12.6-0.5,21.2-0.5l4.4,0
				c0.4,0,0.7,0.2,1.1,0.5c0.3,0.3,0.5,0.9,0.5,1.6l-0.1,70.4c0,1.4,0.3,2.5,0.9,3.2c0.6,0.7,1.3,1.2,2.1,1.4c0.8,0.2,1.8,0.4,3,0.5
				c0.8,0.1,1.3,0.2,1.5,0.5c0.2,0.2,0.4,0.7,0.4,1.5c0,0.5-0.2,0.9-0.5,1.4c-0.3,0.5-0.8,0.7-1.3,0.7l-18.2,0
				c-1.3,0-2.3-0.2-2.9-0.6c-0.6-0.4-0.9-1-1-1.7c-0.1-0.7-0.1-1.8-0.1-3.4c-1.7,2.3-4.2,4.1-7.5,5.3c-3.3,1.2-6.6,1.9-9.9,1.9
				C288.1,83.5,283.6,82.2,280,79.7z M306.5,74.5c1.8-0.9,3.1-2.1,4-3.6l0-31.3c-0.3-0.8-0.9-1.7-1.8-2.6c-0.9-0.9-2-1.6-3.2-2.2
				c-1.2-0.6-2.3-0.9-3.4-0.9c-5.1,0-8.8,1.9-11,5.7c-2.2,3.8-3.3,9.2-3.3,16.3c0,13.2,4.4,19.9,13.4,19.9
				C302.9,75.8,304.7,75.4,306.5,74.5z"/>
			<path class="st1" d="M146.8,76c-5-4.8-7.4-11.8-7.4-20.8c0-5.7,1.3-10.5,4-14.6c2.7-4.1,6.2-7.2,10.7-9.3
				c4.5-2.1,9.4-3.1,14.7-3.1c7.8,0,13.6,1.5,17.5,4.6c3.8,3.1,6.2,7.5,7.2,13.4c0.3,1.9,0.5,4.8,0.5,8.7l-36.6,0
				c0,5.1,0.8,9.2,2.4,12.4c1.6,3.2,3.6,5.4,6.1,6.8c2.5,1.4,5.1,2.1,7.8,2.1c3.2,0,6-0.4,8.2-1.3c2.2-0.8,4.1-2.2,5.8-4.2
				c0,0,0.1-0.1,0.3-0.3c0.2-0.2,0.3-0.3,0.5-0.4c0.2-0.1,0.4-0.1,0.6-0.1l2.3,1.1c0.4,0.1,0.5,0.4,0.5,0.6c0,0.2-0.1,0.5-0.3,0.9
				c-1.9,3.3-4.7,5.9-8.4,7.8c-3.7,1.9-8.2,2.8-13.5,2.8C159.4,83.3,151.8,80.9,146.8,76z M174.6,36.8c-1.2-2.7-3.2-4.1-5.9-4.1
				c-2.9,0-5.3,1.4-7.4,4.2c-2.1,2.8-3.3,7.2-3.8,13.1l18.9,0C176.4,43.9,175.8,39.5,174.6,36.8z"/>
			<path class="st1" d="M198.5,81.1c-0.3-0.5-0.5-1-0.5-1.5c0-0.6,0.1-1.1,0.4-1.4c0.2-0.3,0.7-0.5,1.4-0.6c3.7-0.5,5.6-1.6,5.6-3.4
				l0.1-33.6c0-1.6-0.3-2.9-0.9-3.7c-0.6-0.8-1.3-1.4-2.1-1.7c-0.8-0.3-1.8-0.5-3.1-0.7c-1-0.1-1.6-0.2-1.8-0.3
				c-0.4-0.2-0.7-0.4-0.9-0.7c-0.2-0.3-0.3-0.8-0.3-1.4c0-0.5,0.1-1,0.3-1.5c0.2-0.5,0.5-0.8,0.9-0.8c8.2-0.5,15.7-0.7,22.3-0.7
				c0.4,0,0.7,0.2,1,0.5c0.3,0.3,0.5,0.9,0.6,1.6l0,5.9c0.8-0.9,2.3-2.1,4.5-3.6c2.2-1.4,4.8-2.7,7.7-3.7c2.9-1.1,5.8-1.6,8.7-1.6
				c5.2,0,9.3,1.1,12.4,3.4c3.1,2.2,4.6,6.4,4.6,12.4l0,28.6c0,2,0.5,3.3,1.5,3.9c1,0.6,2,1,3.1,1.1c1.1,0.1,1.8,0.2,2.1,0.2
				c0.6,0.1,1.1,0.2,1.3,0.5c0.3,0.3,0.4,0.7,0.4,1.5c0,0.5-0.2,1-0.5,1.5s-0.8,0.7-1.3,0.7l-28.4,0c-0.6,0-1-0.2-1.3-0.7
				c-0.3-0.5-0.5-1-0.5-1.5c0-0.6,0.1-1.1,0.3-1.4c0.2-0.3,0.7-0.5,1.3-0.6c1.3-0.1,2.5-0.5,3.5-1.1c1-0.6,1.6-1.4,1.6-2.3l0-30
				c0-1.4-0.1-2.6-0.3-3.7c-0.2-1.1-0.8-2.1-1.7-3c-0.9-0.9-2.3-1.3-4.2-1.3c-1.8,0-3.6,0.3-5.5,1c-1.9,0.7-3.6,1.5-5.2,2.6
				c-1.6,1-2.8,2-3.7,3l0,29.7c0,1.4,0.3,2.5,0.8,3.2c0.5,0.7,1.1,1.2,1.9,1.4c0.7,0.2,1.7,0.4,2.9,0.5c0.8,0.1,1.3,0.2,1.5,0.5
				c0.2,0.2,0.4,0.7,0.4,1.5c0,0.5-0.2,0.9-0.5,1.4c-0.3,0.5-0.8,0.7-1.3,0.7l-27.7,0C199.2,81.8,198.8,81.6,198.5,81.1z"/>
			<path class="st1" d="M137.1,78c-0.2-0.2-0.8-0.4-1.5-0.5c-1.3-0.1-2.4-0.3-3.2-0.5c-0.8-0.2-1.6-0.6-2.2-1.3c-0.7-0.7-1-1.6-1-2.8
				l0.1-70.9c0-0.7-0.2-1.3-0.5-1.6c-0.3-0.3-0.7-0.5-1.1-0.5c-8.1,0-15.9,0.2-23.5,0.7c-0.4,0-0.7,0.3-0.9,0.8
				c-0.2,0.5-0.4,1-0.4,1.5c0,0.6,0.1,1.1,0.3,1.4c0.2,0.3,0.5,0.6,0.9,0.7c0.1,0,0.8,0.1,2,0.3c1.4,0.2,2.6,0.4,3.5,0.7
				c0.9,0.3,1.7,0.8,2.4,1.7c0.7,0.8,1,2.1,1,3.8L113,74.7c0,0.9-5.3,3-9.1,3s-8.8-2.1-9.2-3.1c-0.4-0.9-0.6-2.1-0.6-3.5l0-28.4
				c0-2.9-0.8-5.4-2.4-7.6c-1.6-2.2-4.1-3.9-7.5-5.2c-3.4-1.3-7.7-1.9-12.9-1.9c-3.7,0-7.4,0.4-11.3,1.3c-3.9,0.9-7.2,2.3-9.9,4.2
				c-2.7,1.9-4,4.2-4.1,7c0,2.2,0.8,4,2.4,5.4c1.1,0.9,2.4,1.5,3.9,1.8c1.6,0.2,3.2-0.1,3-2.2c0-0.1,0-0.2,0-0.3
				c-0.4-4.3,1.8-8.8,7.2-11.1c0.2-0.1,0.5-0.2,0.7-0.3c0.9-0.5,3-0.8,5.8-0.8c2.6,0,4.6,0.6,5.9,1.8c1.3,1.2,2,3.3,2,6.5l0,8.7
				c-6,0.6-11.4,1.5-16.2,2.7c-4.8,1.2-8.8,3.1-12.2,5.7c-3.3,2.6-5,6.1-5,10.4c0,3.2,0.8,5.8,2.4,7.9c1.6,2.1,3.7,3.7,6.1,4.7
				c2.4,1,4.8,1.5,7.2,1.5c3.8,0,7.2-0.6,10.4-1.8c3.2-1.2,5.9-3,8.2-5.3l0,0.2c0,1.2,0.2,3.1,0.2,3.1s0.2,1.5,2,2.5
				c1.8,0.9,13,0.2,15,0.2s40.7,0.1,40.7,0.1c0.6,0,1-0.2,1.3-0.7c0.3-0.5,0.5-1,0.5-1.5C137.5,78.8,137.4,78.3,137.1,78z M76.7,71.3
				c-2.2,2.7-5,4-8.5,4c-2.2,0-3.9-0.7-5.1-2.1c-1.2-1.4-1.8-3-1.8-4.9c0-3.4,0.7-6.1,2.2-8c1.5-1.9,3.3-3.3,5.5-4.1
				c2.2-0.8,4.7-1.4,7.7-1.9L76.7,71.3z"/>
			<path class="st1" d="M39.8,76c-0.2-0.6-0.5-1.2-0.9-1.7c-0.4-0.5-0.7-0.7-0.9-0.6c-1.3,0.7-2.8,1.2-4.4,1.7
				c-1.6,0.5-3,0.7-4.1,0.7c-1.3,0-2.3-0.3-3-0.9c-0.7-0.6-1.2-1.7-1.5-3.2c-0.3-1.6-0.4-3.8-0.4-6.8l0-30.4l11.4,0
				c0.4,0,0.7-0.4,1.1-1.1c0.3-0.7,0.5-1.7,0.5-2.7c0-0.5-0.1-0.9-0.3-1.2c-0.2-0.4-0.5-0.5-0.9-0.6l-11.7,0l0-14.7
				c0-0.6-0.4-1-1.1-1.3c-1.3-0.4-2.1,1-2.1,1c-3.6,8.2-10.5,14.5-19.2,17.2c0,0-1.8,0.4-1.3,2.3C1,33.6,1.1,33.8,1.2,34
				c0.2,0.5,0.5,0.8,0.9,0.8l5.6,0l-0.1,35c0,5,1.5,8.4,4.5,10.4c3,2,7,3,12.1,3c1.6,0,3.4-0.3,5.5-0.9c2.1-0.6,4.1-1.4,5.9-2.3
				c1.8-0.9,3.2-1.9,4.1-2.8C40,77,40,76.6,39.8,76z"/>
		</g>
	</g>
	</svg>
	);
}
