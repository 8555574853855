import { useRef, useEffect } from 'react';

export function useDrop(options = {}) {
	const dropZone = useRef(null);
	const onDragOverOption = options.onDragOver;
	const onDropOption = options.onDrop;

	useEffect(() => {
		function onDragOver(e) {
			e.stopPropagation();
			e.preventDefault();
			e.dataTransfer.dropEffect = 'copy';
			if (onDragOverOption) {
				onDragOverOption(e);
			}
		}

		function onDrop(e) {
			e.stopPropagation();
			e.preventDefault();
			// var files = e.dataTransfer.files; // Array of all files
			if (onDropOption) {
				onDropOption(e);
			}
		}

		const currentDropZone = dropZone.current;
		if (currentDropZone) {
			currentDropZone.addEventListener('dragover', onDragOver);
			currentDropZone.addEventListener('drop', onDrop);
		}

		return () => {
			if (currentDropZone) {
				currentDropZone.removeEventListener('dragover', onDragOver);
				currentDropZone.removeEventListener('drop', onDrop);
			}
		};
	}, [onDragOverOption, onDropOption]);

	return dropZone;
}
