import React from 'react';

import './ReportDetailLink.css';

export default function ReportDetailLink(props) {
	return (
		<div className="ReportDetailLink__container">
            <img src={props.icon} alt={`${props.title} icon`} className="ReportDetailLink__icon" />
			<div className="ReportDetailLink__description">
				<h5 className="ReportDetailLink__title">{props.title}</h5>
				<p>{props.description}</p>
			</div>
			<a href={`#${props.id}`} className="ReportDetailLink__link">
				See the report
                <i className="fa fa-angle-double-down" />
			</a>
		</div>
	);
}
