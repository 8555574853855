import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import TrustScoreReport from './TrustScoreReport';
import Message from './Message';

const ShowReport = () => {
	const { reportId } = useParams();
	const [message, setMessage] = useState('');
	const [trustScoreReport, setTrustScoreReport] = useState(null);

	useEffect(() => {
		axios
			.get(`/assessor/reports/${reportId}`)
			.then(res => {
				const body = res.data;
				setTrustScoreReport(body);
			})
			.catch(error => {
				if (error.response) {
					setMessage(error.response.data.message);
				}
			});
	}, [reportId]);

	return (
		<React.Fragment>
			{message ? <Message msg={message} /> : null}

			{trustScoreReport ? (
				<TrustScoreReport content={trustScoreReport} />
			) : null}
		</React.Fragment>
	);
};

export default ShowReport;
