import React from 'react';
import './RecoValidity.css';

const DEFAULT_VALUE = {
	name: 'Example State',
	semantic_type: 'US state code',
	total: 7834,
	valid: 5654,
	invalid: 377,
	empty: 1803,
	distinct: 52,
	length: {
		min: 0,
		max: 5,
		average: 2.1
	},
	patterns: [
		{
			pattern: "AA",
			frequency: 0.72
		},
		{
			pattern: "AAA",
			frequency: 0.06
		},
		{
			pattern: "AAAAA",
			frequency: 0.03
		},
	]
};

export default function RecoValidity(props) {
	const data = props.data.recommendation.validity_breakdown.remarked_columns;
	let first;
	if (data.length > 0) {
		first = data[0];
	} else {
		first = DEFAULT_VALUE;
	}
	if (first.invalid === 0) {
		first = DEFAULT_VALUE;
	}
	let maxPatternFreq = 1;
	if (first.patterns) {
		maxPatternFreq = first.patterns.reduce((acc, value) => {
			return Math.max(acc, value.frequency);
		}, 0);
	}

	return (
		<div className="RecoValidity__panel">
			<div className="Report__panel">
				<i className="fa fa-caret-down" />
				<h5 className="RecoValidity__title">{first.name}</h5>
				<p className="RecoValidity__semantic">
					{first.semantic_type === '' ? first.data_type : first.semantic_type}
				</p>
				<div className="progress">
					<div
						className="progress-bar bg-warning"
						role="progressbar"
						style={{ width: `${100.0 * first.invalid / (first.valid + first.invalid)}%` }}
						aria-valuenow="15"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
					<div
						className="progress-bar bg-success"
						role="progressbar"
						style={{ width: `${100.0 * first.valid / (first.valid + first.invalid)}%` }}
						aria-valuenow="30"
						aria-valuemin="0"
						aria-valuemax="100"
					></div>
				</div>
				<ul className="list-unstyled inconsolata RecoValidity__list">
					<li>
						<span className="RecoValidity__type">Count: </span>
						<span className="RecoValidity__value">{first.total}</span>
					</li>
					<li>
						<span className="RecoValidity__type">Valid: </span>
						<span className="RecoValidity__value">{first.valid}</span>
					</li>
					<li>
						<span className="RecoValidity__type">Invalid: </span>
						<span className="RecoValidity__value">{first.invalid}</span>
					</li>
					<li>
						<span className="RecoValidity__type">Distinct: </span>
						<span className="RecoValidity__value">{first.distinct}</span>
					</li>
					{first.length && (
						<>
							<li>
								<span className="RecoValidity__type">Min length: </span>
								<span className="RecoValidity__value">{first.length.min}</span>
							</li>
							<li>
								<span className="RecoValidity__type">Max length: </span>
								<span className="RecoValidity__value">{first.length.max}</span>
							</li>
							<li>
								<span className="RecoValidity__type">Avg length: </span>
								<span className="RecoValidity__value">{first.length.average.toFixed(1)}</span>
							</li>
						</>
					)}
					{first.numeric && (
						<>
							<li>
								<span className="RecoValidity__type">Min: </span>
								<span className="RecoValidity__value">{first.numeric.min}</span>
							</li>
							<li>
								<span className="RecoValidity__type">Max: </span>
								<span className="RecoValidity__value">{first.numeric.max}</span>
							</li>
							<li>
								<span className="RecoValidity__type">Mean: </span>
								<span className="RecoValidity__value">{first.numeric.mean}</span>
							</li>
						</>
					)}
				</ul>
			</div>
			<div className="RecoValidity__patterns Inconsolata">
				{first.patterns && (
					<>
						<h5>Patterns</h5>
						{first.patterns.filter(pf => pf.pattern !== '').slice(0, 3).map(pf => (
							<div className="progress" key={pf.pattern}>
								<div
									className="pattern progress-bar"
									role="progressbar"
									style={{ width: `${(pf.frequency * 100) / maxPatternFreq}%` }}
									aria-valuenow={(pf.frequency * 100) / maxPatternFreq}
									aria-valuemin="0"
									aria-valuemax="100"
								>
									<span className="RecoValidity__pattern">{pf.pattern}</span>
								</div>
							</div>
						))}
					</>
				)}
			</div>
		</div>
	);
}
