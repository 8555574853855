import React from 'react';
import Logo from './Logo';
import PieChartIconComponent from './PieChartIcon';
import './TrustScoreReport.css';
import BigNumber from './BigNumber';
import ReportDetailLink from './ReportDetailLink';
import RecoValidity from './RecoValidity';
import RecoCompleteness from './RecoCompleteness';
import RecoUniqueness from './RecoUniqueness';
import { sortBy } from '../sort';

class TrustScoreReport extends React.Component {
	render() {
		const { content } = this.props;
		const totalCells = content.file_metadata.row_count * content.file_metadata.column_count;
		let items;
		if (content.column_stats) {
			items = content.column_stats.sort(sortBy('id')).map(item => (
				<tr key={item.id}>
					<th scope="col">{item.id}</th>
					<td>{item.name}</td>
					<td>{item.data_type}</td>
					<td>
						{item.semantic_type}{' '}
						{item.semantic_type === '' ? null : '(' + item.semantic_type_frequency.toFixed(2) + '%)'}
					</td>
					<td>{item.used_for_validation}</td>
					<td>{item.valid}</td>
					<td>{item.empty}</td>
					<td>{item.invalid}</td>
					<td>{item.distinct}</td>
					<td>{item.weight.toFixed(3)}</td>
					<td>{item.weighted_validity_score.toFixed(3)}</td>
					<td>{item.weighted_completeness_score.toFixed(3)}</td>
				</tr>
			));
		}

		const columnsWithValidityIssue = content.recommendation.validity_breakdown.remarked_columns;
		const validityRecommendationItems = columnsWithValidityIssue.map(item => (
			<tr key={item.name}>
				<td>{item.name}</td>
				<td>
					<b>{(item.invalid * 100.0 / item.total).toFixed(1)}%</b> invalid
				</td>
			</tr>
		));

		const columnsWithCompletenessIssue = content.recommendation.completeness_breakdown.remarked_columns;
		const completenessRecommendationItems = columnsWithCompletenessIssue.map(item => (
			<tr key={item.name}>
				<td>{item.name}</td>
				<td>
					<b>{(item.empty * 100.0 / item.total).toFixed(1)}%</b> empty
				</td>
			</tr>
		));
		if (!content) {
			return null;
		}
		return (
			<div>
				<div className="mt-0 report-summary">
					<div className="Report__logo">
						<a href="https://talend.com" title="Talend Real-Time Open Source Data Integration Software"><Logo /></a>
					</div>
					{/* <center className="d-print-none my-2">
                        <button className="btn btn-light" onClick={() => window.print()}>Print Report</button>
                    </center> */}
					<div className="container">
						{/*<!-- Report Summary -->*/}
						<div className="row my-5">
							<div className="col-md-7 text-left">
								<h1>Your Talend Trust Score Report</h1>
								<p />
								<p>
									On a scale where 100 means a fully trusted and accurate dataset,
									{' '}<strong>your Talend Trust Score is {content.trust_score.overall.toFixed(0)}.</strong>
									{content.trust_score.overall < 100 && (
										<>{' '}(Ready to raise your score now ? We can help.)</>
									)}
								</p>
								<a
									type="button"
									className="btn btn-secondary btn-lg"
									id="button-section-header"
									href="https://www.talend.com/contact-sales/"
									role="button"
								>
									Contact us
								</a>
								<p />
								<p>Let’s dig into your data and see what’s affecting your score.</p>
							</div>
							<div className="col-md-5">
								<div className="Report__gauge">
									<BigNumber value={content.trust_score.overall.toFixed(0)}>
										<image href="/talend_trust_score.svg" x="90" y="230" width="200px"></image>
									</BigNumber>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container">

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2>Calculating your score</h2>
							<p>
								The enterprise Talend Trust Score inside Data Inventory checks that your data is thorough,
								transparent, timely, traceable, and tested — we call these the <strong>5 T’s of data trust</strong>. More specifically,
								it measures data quality, data popularity, and user defined ratings. But for this report, let’s dive
								into some critical components of data quality.
							</p>
							<p>
								The Talend Trust Assessor reflects three critical aspects of your data. In the
								report below, each aspect gets its own detailed score, which comes with
								specific insights and recommended actions.
							</p>
							<ReportDetailLink
								id="validity"
								title="Validity"
								icon={`${process.env.PUBLIC_URL}/most-trusted.svg`}
								description="Is your data correctly formatted and stored in the right way?"
							/>
							<ReportDetailLink
								id="completeness"
								title="Completeness"
								icon={`${process.env.PUBLIC_URL}/list.svg`}
								description="Does your dataset include values for all the fields required?"
							/>
							<ReportDetailLink
								id="uniqueness"
								title="Uniqueness"
								icon={`${process.env.PUBLIC_URL}/numbers.svg`}
								description="Is your data free from duplicates and dummy entries?"
							/>
						</div>
						<div className="col-lg-4">
							<div className="Report__portlet">
								<h5>Why trust?</h5>
								<p>
									The goal of data is to provide insight, and that can only happen when you trust
									your data. After all, if your data isn’t an accurate representation of reality, it
									can only lead you to poor decisions. But when you know that you can trust your
									data, you will be better informed and perfectly positioned to make strategic
									businesses decisions with confidence.
								</p>
							</div>
						</div>
					</div>
				</div>

				<hr className="col-md-3 text-center d-print-none" />

				<div className="pagebreak" />

				<div className="container">
					{/*<!-- Data Validity Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="validity">Data Validity Assessment</h2>
							<p />
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								Data Validity is a measure of how effectively your organization conforms to a
								business process. Every piece of information that comes into your system must
								conform to specific rules for type, format, and range; common rules are for things
								like phone number, ZIP code, or email address.
							</p>
							<p>
								While it may seem small, even slight variances, such as “New York” versus “NY” in a
								state field, could skew your analytics and lead to the wrong decision about where to
								open another store or increase your marketing spend. That is why data validity is so
								critical to your data trust.
							</p>
							{content.recommendation.validity_breakdown.total_invalid_count > 0 && (
								(content.recommendation.validity_breakdown.score_level === 'high' ?
									<p>
										Overall, you’re in pretty good shape! If you want to go the extra mile, in the table we've listed some areas
										that could use a little attention.
									</p>
									:
									<p>
										We found a few issues with your data validity. In the table we've listed some areas that could use
										your attention.
									</p>
								)
							)}
						</div>
						<div className="col-lg-4 m-auto text-center">
							<h5>
								<b>Data Validity</b> breakdown:
							</h5>
							<div className="d-flex justify-content-center">
								<PieChartIconComponent
									display="xlarge"
									model={[
										{
											color: 'jaffa',
											percentage:
												100.0 * content.recommendation.validity_breakdown.total_invalid_count / totalCells,
										},
									]}
								>
									Invalid values
								</PieChartIconComponent>
							</div>
							{validityRecommendationItems.length > 0 && (
								<>
									<h5>
										<b>Top columns</b> that could use attention:
									</h5>

									<table className="table inconsolata">
										<tbody>{validityRecommendationItems}</tbody>
									</table>
								</>
							)}
						</div>
					</div>

					<div className="pagebreak" />

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>
							{(content.recommendation.validity_breakdown.score_level === 'high' ?
								<p>
									We didn’t find many issues with this dataset, which shows that you take data validity
									seriously. Talend can help you streamline and automate data validity in the future with
									the following functionality:
								</p>
								:
								<p>
									Not to worry: you don’t need a heavy manual process to repair this dataset. Not only
									does Talend clean new data before it enters your system, we have built-in checks at
									every stage of the data lifecycle to identify and correct common data validity
									errors. Here are some of our capabilities that will not only address the concerns
									within this dataset but can help improve your data validity score across all your
									data:
								</p>
							)}
							<div className="Report__productlist">
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend data preparation logo"
										src="/tdp-colored.svg"
									/>
									<p>
										Talend <strong>Data Preparation</strong> provides simple yet powerful built-in
										profiling capabilities to explore datasets and assess their quality with the help
										of indicators, trends, and patterns.
									</p>
								</div>
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend data stewardship logo"
										src="/tds-colored.svg"
									/>
									<p>
										Talend <strong>Data Stewardship</strong> allows you to define and maintain data
										models, document and cleanse the data, and establish clear data rules and
										policies.
									</p>
								</div>
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend pipeline designer"
										src="/tpd-colored.svg"
									/>
									<p>
										Talend <strong>Pipeline Designer</strong> provides the fastest and easiest way to design and
										automate your quality rules and processes into a data pipeline.
									</p>
								</div>
							</div>
							<a className="btn btn-outline-secondary d-print-none" id="button-section-1" href="https://www.talend.com/free-trial">
								Get Started
							</a>
						</div>
						<div className="col-lg-4 mt-5">
							<RecoValidity data={content} />
						</div>
					</div>

					<hr className="col-md-3 text-center pagebreak" />

					{/*<!-- Data Completeness Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="completeness">Data Completeness Assessment</h2>
							<p />
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								Data completeness looks at the data required by your system and tells you how much
								of it is actually available. Data completeness may sound simple, but certain
								complexities often go overlooked. For example, if a name record is missing a first
								name or gender, that record may be incomplete. If you try to use or segment that
								data, or combine it with data from other systems, you might have to stop everything
								for time-consuming manual data cleanups or, even worse, the missing data might go
								unnoticed and result in serious errors.
							</p>

							{content.recommendation.completeness_breakdown.total_empty_count > 0 && (
								<p>
									When we analyzed your dataset of <strong>{totalCells}</strong> expected pieces of data,
									we found that
									{' '}<strong>{content.recommendation.completeness_breakdown.total_empty_count}</strong>{' '}
									were missing. The table shows which columns are the least complete.
								</p>
							)}
						</div>
						<div className="col-lg-4 mt-5 text-center">
							<h5>
								<b>Data Completeness</b> breakdown:
							</h5>
							<div>
								<PieChartIconComponent
									display="xlarge"
									model={[
										{
											color: 'dove-gray',
											percentage:
												100.0 * content.recommendation.completeness_breakdown.total_empty_count / totalCells,
										},
									]}
								>
									Empty values
								</PieChartIconComponent>
							</div>
							{completenessRecommendationItems.length > 0 && (
								<>
									<h5>
										<b>Top columns</b> that could use attention:
									</h5>

									<table className="table inconsolata">
										<tbody>{completenessRecommendationItems}</tbody>
									</table>
								</>
							)}
						</div>
					</div>

					<div className="pagebreak" />

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>

							{(content.recommendation.completeness_breakdown.score_level === 'high' ?
								<p>
									While this dataset ranks high for completeness, not every dataset in your company looks
									like this one. If you partner with Talend, we can provide you with a holistic look at
									your data. You might be interested in some of the other capabilities we have to offer:
								</p>
								:
								<p>
									Resolving your issues with incomplete data could improve your ability to use your data
									effectively. If you partner with Talend, we can provide you with a holistic look at your
									data. You might be interested in some of the other capabilities we have to offer:
								</p>
							)}
							<div className="Report__productlist">
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend data preparation logo"
										src="/tdp-colored.svg"
									/>
									<p>
										Talend <strong>Data Preparation</strong> is a self-service application that
										allows potentially anyone to access a dataset and cleanse, standardize,
										transform, or enrich the data.
									</p>
								</div>
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend data inventory logo"
										src="/tdc-colored.svg"
									/>

									<p>
										In addition to providing a Talend Trust Score, Talend <strong>Data Inventory</strong> has
										advanced search technology that cuts the time it takes to find and consume the data you need.
									</p>
								</div>
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend pipeline designer logo"
										src="/tpd-colored.svg"
									/>
									<p>
										Talend <strong>Pipeline Designer</strong> provides the fastest and easiest way
										to design data pipelines that enrich and complete your datasets.
									</p>
								</div>
							</div>
							<a className="btn btn-outline-secondary d-print-none" id="button-section-2" href="https://www.talend.com/free-trial">
								Get Started
							</a>
						</div>
						<div className="col-lg-4 mt-5">
							<RecoCompleteness data={content} />
						</div>
					</div>

					<hr className="col-md-3 text-center pagebreak" />

					{/*<!-- Data Uniqueness Assessment -->*/}
					<div className="row report-breakdown py-4">
						<div className="col-lg-8 m-auto text-left">
							<h2 id="uniqueness">Data Uniqueness Assessment</h2>
							<h6>
								<b>INSIGHTS</b>
							</h6>
							<p>
								It isn’t always easy to identify duplicate records. If four leads come in as ABC
								Company, Abc Inc, ABC Corp., and ABC incorporated, you might think nothing of it.
								But if they all have the address 1111 Acme Road… well, something doesn’t seem right
								there.
							</p>
							<p>
								When it comes to duplicate data, always consider the 1-10-100 rule: if it only costs
								$1 to verify a record as it’s entered, it will cost $10 to cleanse and dedupe it
								later, and $100 if you do nothing. What would that mean for you if you had 10,000
								duplicate records across your organization?
							</p>

							{content.trust_score.record_uniqueness < 1 && (
								(content.recommendation.uniqueness_breakdown.score_level === 'high' ?
									<p>
										Good news! In the dataset you provided, we just found
										{' '}<strong>{content.recommendation.uniqueness_breakdown.duplicate_record_count}</strong>{' '}
										duplicates out of <strong>{content.file_metadata.row_count}</strong> total records. If you wanted to go
										even further — or were looking for strategies to apply to other datasets — here are a few things
										to consider:
									</p>
									:
									<p>
										In the dataset you provided, we found
										{' '}<strong>{content.recommendation.uniqueness_breakdown.duplicate_record_count}</strong>{' '}
										duplicates out of <strong>{content.file_metadata.row_count}</strong> total records. We’d recommend
										considering a few strategies to help clean up this data:
									</p>
								)
							)}
						</div>
						<div className="col-lg-4 mt-5 text-center">
							<h5>
								<b>Data Uniqueness</b> breakdown:
							</h5>
							<PieChartIconComponent
								display="xlarge"
								model={[
									{
										color: 'lochmara',
										percentage: (1 - content.trust_score.record_uniqueness) * 100,
									},
								]}
							>
								Duplicates
							</PieChartIconComponent>
						</div>
					</div>

					<div className="pagebreak" />

					<div className="row report-breakdown py-4">
						<div className="col-lg-8 mt-auto text-left">
							<h6>
								<b>RECOMMENDATIONS</b>
							</h6>

							{(content.recommendation.uniqueness_breakdown.score_level === 'high' ?
								<p>
									If only your data could stay as clean as it is now forever! But every day new data is
									coming into your system, increasing the chances of adding duplicate records. Talend’s
									advanced functionality can help make that process painless for you and your team:
								</p>
								:
								<p>
									You have some work to do here — and every day new data is coming into your system,
									increasing the chances of adding duplicate records. Talend’s advanced functionality
									can help make that process painless for you and your team:
								</p>
							)}
							<div className="Report__productlist">
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend data stewardship logo"
										src="/tds-colored.svg"
									/>
									<p>
										Talend <strong>Data Stewardship</strong> automates data cleansing and validation, then empowers
										the people who know your data best to curate and certify your data.
									</p>
								</div>
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend data preparation logo"
										src="/tdp-colored.svg"
									/>{' '}
									<p>
										Talend <strong>Data Preparation</strong> allows potentially anyone to access a
										dataset, then cleanse, dedupe, standardize, transform, or enrich the data.
									</p>
								</div>
								<div className="Report__productdesc">
									<img
										className="Report__productlogo"
										alt="talend pipeline designer logo"
										src="/tpd-colored.svg"
									/>{' '}
									<p>
										Talend <strong>Pipeline Designer</strong> provides the fastest and easiest way to design and automate,
										schedule, and monitor processes into a data pipeline to resolve and keep your data valid.
									</p>
								</div>
							</div>
							<a className="btn btn-outline-secondary d-print-none" id="button-section-3" href="https://www.talend.com/free-trial">
								Get Started
							</a>
						</div>
						<div className="col-lg-4 mt-5">
							<RecoUniqueness data={content} />
						</div>
					</div>

					<hr className="col-md-5 text-center pagebreak" />

					{/*<!-- final CTA -->*/}
					<div className="row py-4">
						<div className="col-md-8 m-auto text-left">
							<h1>
								<b>Your data assessment summary</b>
							</h1>
							<p>
								As we move forward in the digital era, it will only become more important for
								every company to have a clear digital strategy. We must have the appropriate
								processes and technologies in place for identifying, understanding, managing, and
								securing data if we are going to support essential business operations and improve
								strategic decision making.
							</p>
							<p>
								Talend can help you with your data transformation by providing guidance, control,
								and transparency in a single, easily managed platform. To truly understand the health
								of your data, you need data quality, data popularity, and user-defined ratings.
							</p>
							<p>
								<b>Reach out today</b> and let Talend bring you one step closer to the clarity you need
								to make all your strategic businesses decisions with confidence.
							</p>
						</div>
						<div className="col-md-4 m-auto text-center">
							<a
								type="button"
								className="btn btn-secondary btn-lg"
								id="button-section-cta"
								href="https://www.talend.com/contact-sales/"
								role="button"
							>
								Contact us
							</a>
						</div>
					</div>
				</div>

				<div className="mt-5 report-footer">
					<div className="container">
						<div className="row">
							<div className="col">
								<a
									type="button"
									className="btn btn-outline-secondary btn-lg"
									id="button-section-footer"
									href="/"
									role="button"
								>
									<i className="fa fa-arrow-left" />
									Try another file
								</a>

								<hr className="col-md-12 text-center pagebreak" />

								<div className="footer-text">
									<p>
										© 2020 Talend, Inc. All rights reserved.
									</p>
									<p>
										All text and images herein are legally protected (unless previously public
										domain) and are not to be used, reproduced, modified or distributed without
										written consent of Talend Inc.
									</p>
									<p>
										The following trademarks, service marks and logos (“Trademarks”) used and displayed
										herein are registered and unregistered Trademarks of Talend S.A., the parent company
										of Talend, Inc.: Talend, Talend Trust Score, and the design marks used in connection
										therewith. Nothing herein should be construed as granting any license or right to use
										such Trademarks, without the written permission of the Trademark owner. Use of Talend
										Inc.’s products and services and generation of this report should not be construed as
										endorsement, sponsorship, or affiliation, by or with Talend Inc. or any of its affiliates.
										Talend S.A. and Talend Inc. reserve the right to enforce its intellectual property rights
										to the fullest extent of the law.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				{window.location.search.indexOf('debug') !== -1 && (
					<div className="row mt-0 report-detail d-print-none">
						<div className="col-md-8 m-auto text-center">
							<h1>Detailed Metadata</h1>
						</div>
						<div className="col-md-6 m-auto mx-2">
							<table className="table inconsolata">
								<tbody>
									<tr>
										<th scope="col">File Name</th>
										<td>{content.file_metadata.file_name_hash}</td>
									</tr>
									<tr>
										<th scope="col">File Size (KB)</th>
										<td>{content.file_metadata.file_size}</td>
									</tr>
									<tr>
										<th scope="col">File Type</th>
										<td>{content.file_metadata.file_type}</td>
									</tr>
									<tr>
										<th scope="col">Column Count</th>
										<td>{content.file_metadata.column_count}</td>
									</tr>
									<tr>
										<th scope="col">Row Count</th>
										<td>{content.file_metadata.row_count}</td>
									</tr>
									<tr>
										<th scope="col">Encoding</th>
										<td>{content.file_metadata.encoding}</td>
									</tr>
									<tr>
										<th scope="col">Delimiter</th>
										<td>{content.file_metadata.delimiter}</td>
									</tr>
									<tr>
										<th scope="col">Contains Header</th>
										<td>{content.file_metadata.has_header.toString()}</td>
									</tr>
									<tr>
										<th scope="col">Semantic Type Ratio (stratio)</th>
										<td>{content.trust_score.st_ratio.toFixed(2)}</td>
									</tr>
									<tr>
										<th scope="col">Semantic Validity (qst)</th>
										<td>{content.trust_score.semantic_validity.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Primitive Validity (qpt)</th>
										<td>{content.trust_score.primitive_validity.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Overall Validity</th>
										<td>{content.trust_score.validity.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Completeness (compl)</th>
										<td>{content.trust_score.completeness.toFixed(3)}</td>
									</tr>
									<tr>
										<th scope="col">Record Uniqueness</th>
										<td>{content.trust_score.record_uniqueness.toFixed(3)}</td>
									</tr>
								</tbody>
							</table>
						</div>

						<div className="mx-5">
							<table className="table table-striped table-bordered inconsolata">
								<thead className="thead-dark">
									<tr>
										<th scope="col">#</th>
										<th scope="col">Column Name</th>
										<th scope="col">Data Type</th>
										<th scope="col">Semantic Type</th>
										<th scope="col">Used For Validation</th>
										<th scope="col">Valid</th>
										<th scope="col">Empty</th>
										<th scope="col">Invalid</th>
										<th scope="col">Distinct</th>
										<th scope="col">Weight</th>
										<th scope="col">Weighted Validity</th>
										<th scope="col">Weighted Completeness</th>
									</tr>
								</thead>
								<tbody>{items}</tbody>
							</table>
						</div>
					</div>
				)}
			</div>
		);
	}
}

export default TrustScoreReport;
