import React from 'react';
import { Navigate } from 'react-router';
import { STORAGE_KEY } from '../auth';

function SaveUser(props) {
	React.useEffect(() => {
		localStorage.setItem(STORAGE_KEY, props.match.params.uid);
	}, [props.match.params.uid]);
	return (
		<Navigate to="/" />
	);
}

export default SaveUser;
