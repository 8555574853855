export const CSRF_COOKIE = 'XSRF-TOKEN';
export const CSRF_HEADER = 'X-XSRF-TOKEN';

export function getCSRFCookie() {
	const cookies = decodeURIComponent(document.cookie);
	return cookies.split(';').map(s => s.split('=')).find(s => s[0] === CSRF_COOKIE);
}

export function getCSRFHeader() {
    const csrf = getCSRFCookie();
	if (csrf) {
		return { [CSRF_HEADER]: csrf[1] };
	}
	return {};
}
