import { useEffect, useState } from 'react';
import axios from 'axios';
export const STORAGE_KEY = '@talend-trust-assessor';

export function getKey() {
	const content = localStorage.getItem(STORAGE_KEY);
	return content;
}

export function isAuthorized() {
	return !!getKey();
}

export function saveKey(key) {
	localStorage.setItem(STORAGE_KEY, key);
}

export function isValidKey() {
	const key = getKey();
	if (!key) {
		return Promise.reject();
	}
	return axios
		.get(`/user/${key}`)
		.then(() => {
			return true;
		})
		.catch(function (error) {
			if (error.response && error.response.status === 404) {
				return false;
			}
			throw error;
		});
}

export function checkEmail(email) {
	return axios
		.post('/user/check', { email })
		.then(response => {
			saveKey(response.data.uid);
			return true;
		})
		.catch(function (error) {
			if (error.response && error.response.status === 404) {
				return false;
			}
			console.error(error);
			return false;
		});
}

export function useAuth() {
	const [state, setState] = useState();
	const [status, setStatus] = useState(0);
	useEffect(() => {
		if (status === 1) {
			return;
		}
		const key = getKey();
		if (!key) {
			setStatus(2);
			return;
		}
		isValidKey(key)
			.then(isValid => {
				if (isValid) {
					setStatus(1);
				} else {
					setStatus(3);
				}
			})
			.catch(e => console.error(e));
	}, [status, state]);
	const onChange = () => {
		// force re-render
		setState(Math.random());
	};
	return [status, onChange];
}

export const MESSAGES = {
	0: 'loading',
	1: 'has a valid key',
	2: 'has no key',
	3: 'has key but not valid',
	4: 'has email under validation',
};
