import React from 'react';
import PropTypes from 'prop-types';

const Message = ({ msg, type }) => {
	const [prev, setPrev] = React.useState();
	const [show, setShow] = React.useState();
	React.useEffect(() => {
		if (msg && prev !== msg && !show) {
			setShow(true);
		}
		if (msg !== prev) {
			setPrev(msg);
		}
	}, [msg, setShow, show, prev, setPrev]);
	function onClick(event) {
		setShow(false);
	}
	if (!show) {
		return null;
	}
	let className = "alert alert-dismissible fade show";
	if (type === 'error') {
		className += ' alert-danger';
	} else {
		className += ' alert-info';
	}
	return (
		<div className={className} role="alert">
			{msg}
			<button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={onClick}>
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
	);
};

Message.propTypes = {
	msg: PropTypes.string.isRequired,
};

export default Message;
