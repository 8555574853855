import React from 'react';
import FileUpload from './components/FileUpload';
import ShowReport from './components/ShowReport';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import SaveUser from './components/SaveUser';

export default function App() {
	return (
		<Router>
			<div className="page">
				<Routes>
					<Route path="/" element={<FileUpload />} />
					<Route path="/saveuser/:uid" element={<SaveUser />} />
					<Route path="/report/:reportId" element={<ShowReport other="props" />} />
					<Route path="*" element={<Navigate to="/" />} />
				</Routes>
			</div>
		</Router>
	);
}


